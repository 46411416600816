<template>
  <div class="calDiv">
    <span class="leftBump"></span>
    <span class="rightBump"></span>
    <span class="monthSpan">{{ month }}</span>
    <span class="divider"></span>
    <span class="dayDiv">
      <span class="daySpan">{{ day }}</span>
    </span>
  </div>
</template>

<script>
export default {
  name: "ScoreEntry",
  props: ["date"],
  data() {
    return {
      monthNames: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    };
  },
  computed: {
    month() {
      return this.monthNames[new Date(this.date).getMonth()];
    },
    day() {
      return new Date(this.date).getDate();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
.calDiv
  position: relative
  display: flex
  flex-direction: column
  align-items: center
  border-radius: .5rem
  border: 2px solid $secondary-text-color

.leftBump, .rightBump
  position: absolute
  top: 0
  transform: translateY(-50%)
  width: 15px
  height: 7px
  background: $secondary-text-color

.leftBump
  left: 15%

.rightBump
  right: 15%

.monthSpan
  margin: .25rem .75rem
  color: $secondary-text-color
  text-align: center
  font-size: 1.25rem

.divider
  width: 80%
  border-bottom: 1px solid white

.dayDiv
  flex-grow: 1
  display: flex
  justify-content: center
  align-items: center
  margin: .25rem .75rem

  .daySpan
    color: $primary-text-color
    font-size: 1.375rem
</style>
