import { createStore } from "vuex";

export default createStore({
  state: {
    loaded: false,
    scores: [],
    targetScore: 99.5,
  },
  getters: {
    numerator: (state) => {
      let numerator = 0;
      for (let element of state.scores) {
        numerator += element.score * element.distance;
      }
      return numerator;
    },
    denominator: (state) => {
      let denominator = 0;
      for (let element of state.scores) {
        denominator += element.distance;
      }
      return denominator;
    },
    currentScore: (state, getters) => {
      return getters.numerator / getters.denominator;
    },
    milesToFix: (state, getters) => {
      return (
        (state.targetScore * getters.denominator - getters.numerator) /
        (100 - state.targetScore)
      );
    },
  },
  mutations: {
    setTarget(state, payload) {
      state.targetScore = payload;
      localStorage.setItem("targetScore", JSON.stringify(state.targetScore));
    },
    setScores(state, payload) {
      state.scores = payload;
    },
    addNewScore(state, payload) {
      state.scores.push({
        id: Math.floor(Math.random() * 100000),
        date: payload.date,
        score: payload.score,
        distance: payload.distance,
      });
      localStorage.setItem("scores", JSON.stringify(state.scores));
    },
    removeScore(state, payload) {
      for (let i = 0; i < state.scores.length; i++)
        if (state.scores[i].id == payload) state.scores.splice(i, 1);
      localStorage.setItem("scores", JSON.stringify(state.scores));
    },
  },
  actions: {
    async init({ state, commit }) {
      if (
        localStorage.getItem("scores") != "" &&
        !!localStorage.getItem("scores")
      )
        commit("setScores", JSON.parse(localStorage.getItem("scores")));
      if (
        localStorage.getItem("targetScore") != "" &&
        !!localStorage.getItem("targetScore")
      )
        commit("setTarget", JSON.parse(localStorage.getItem("targetScore")));
      state.loaded = true;
    },
  },
  modules: {},
});
