<template>
  <div class="scoreList">
    <ScoreEntry
      v-for="score in scores"
      :key="score.id"
      :id="score.id"
      :date="score.date"
      :score="score.score"
      :distance="score.distance"
    />
  </div>
</template>

<script>
import ScoreEntry from "@/components/ScoreEntry.vue";

export default {
  name: "ScoreList",
  props: ["msg"],
  components: { ScoreEntry },
  computed: {
    scores() {
      let sortedScores = JSON.parse(JSON.stringify(this.$store.state.scores));
      sortedScores.sort((a, b) =>
        new Date(a.date) > new Date(b.date) ? -1 : 1
      );
      return sortedScores;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
.scoreList
  display: flex
  flex-direction: row
  flex-wrap: wrap
  justify-content: space-around
  gap: 1rem
</style>
