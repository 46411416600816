<template>
  <div @click.self="dismissPopup()" class="popupContainer">
    <div class="popup">
      <button @click="dismissPopup()" id="closeButton">
        <i class="fas fa-times"></i>
      </button>
      <span id="title">How Is This Calculated?</span>
      <div id="body">
        <span>
          Your Tesla Safety Score is a weighted average of all of your daily
          scores.
        </span>

        <div id="currentScoreExplain">
          <span>Your specific score was calculated as follows:</span>
          <br />
          <div id="calculation" style="align-self: center">
            <div id="numerator">
              <span v-for="(score, index) in shortHandScores" :key="index">
                ({{ score.score }} * {{ score.distance }})
                <span v-if="index != shortHandScores.length - 1">+</span>
              </span>
              <span v-if="shortHandScores.length < $store.state.scores.length">
                + ...
              </span>
            </div>
            <span id="divider"></span>
            <div id="denominator">
              <span v-for="(score, index) in shortHandScores" :key="index">
                {{ score.distance }}
                <span v-if="index != shortHandScores.length - 1">+</span>
              </span>
              <span v-if="shortHandScores.length < $store.state.scores.length">
                + ...
              </span>
            </div>

            <span id="equals">=</span>

            <span id="score">
              <span class="toolTip">
                {{ Math.round($store.getters.currentScore) }}
                <span class="toolTipText">
                  Detailed Score: {{ $store.getters.currentScore.toFixed(2) }}

                  <br />
                  Scores are rounded up
                </span>
              </span>
            </span>
            <!-- {{ $store.getters.numerator }} -->
          </div>
        </div>

        <div id="targetScoreExplain">
          <span>
            With your target score of
            <span class="toolTip">
              {{ $store.state.targetScore }},
              <span class="toolTipText">
                Rounded target: {{ Math.round($store.state.targetScore) }}
                <br />
                Scores are rounded up
              </span>
            </span>
            assuming you attempt to achieve a 100 on all subsequent drives,
            total milage required to achieve your target score is calculated as
            follows:
          </span>
          <br />
          <div id="calculation" style="align-self: center">
            <div id="numerator">
              <span v-for="(score, index) in superShortHandScores" :key="index">
                ({{ score.score }} * {{ score.distance }})
                <span v-if="index != superShortHandScores.length - 1">+</span>
              </span>
              <span
                v-if="superShortHandScores.length < $store.state.scores.length"
              >
                + ...
              </span>
              + (100 * x)
            </div>
            <span id="divider"></span>
            <div id="denominator">
              <span v-for="(score, index) in superShortHandScores" :key="index">
                {{ score.distance }}
                <span v-if="index != superShortHandScores.length - 1">+</span>
              </span>
              <span
                v-if="superShortHandScores.length < $store.state.scores.length"
              >
                + ...
              </span>
              + x
            </div>

            <span id="equals">=</span>

            <span id="score">{{ $store.state.targetScore }}</span>
            <!-- {{ $store.getters.numerator }} -->
          </div>
        </div>

        <span id="finalTip">
          Your target score can be changed by tapping the target score on the
          main page
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ExplainScorePopup",
  emits: ["dismissPopup"],
  computed: {
    shortHandScores() {
      return this.$store.state.scores.slice(0, 3);
    },
    superShortHandScores() {
      return this.$store.state.scores.slice(0, 2);
    },
  },
  methods: {
    dismissPopup() {
      this.$emit("dismissPopup");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
.popupContainer
  z-index: 6
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  background: transparentize($primary-background, .05)
  display: grid
  grid-template-columns: 1fr 500px 1fr
  grid-template-rows: 1fr auto 1fr
  grid-template-areas: "top top top" "lm popup rm" "bottom bottom bottom"

  @media screen and (max-width: 550px)
    grid-template-columns: 1fr 90% 1fr

.popup
  grid-area: popup
  background: linear-gradient(145deg, lighten($primary-background, 1%), darken($primary-background, 1%))
  box-shadow: $neu-extrude
  position: relative
  border-radius: .5rem
  display: grid
  grid-template-columns: 1fr auto 1fr
  grid-template-rows: auto auto auto
  grid-template-areas: "lm title top" "lm body rm"
  color: $secondary-text-color
  padding: 2rem
  padding-top: 0

  @media screen and (max-width: 550px)
    padding: 1rem

#title
  grid-area: title
  display: flex
  justify-content: flex-start
  align-items: center
  margin: 2rem 0 1rem 0
  font-size: 1.5rem

  @media screen and (max-width: 550px)
    font-size: 1.25rem
    margin: .5rem 0 1rem 0

#closeButton
  position: absolute
  top: 1rem
  right: 1rem
  margin: 0
  outline: none
  border: 1px solid transparentize($tertiary-accent-color, .8)
  display: flex
  align-items: center
  justify-content: center
  background: transparent
  box-shadow: $neu-extrude
  color: $secondary-text-color
  font-size: 1.5rem
  width: 2.5rem
  height: 2.5rem
  border-radius: .5rem
  cursor: pointer

  transition: color .3s
  &:hover
    color: $tertiary-accent-color

  @media screen and (max-width: 550px)
    top: .5rem
    right: .5rem
    font-size: 1.25rem

#body
  grid-area: body
  display: flex
  flex-direction: column
  gap: 1.5rem

#currentScoreExplain
  display: flex
  flex-direction: column
  grid-gap: .25rem

#targetScoreExplain
  display: flex
  flex-direction: column
  grid-gap: .25rem

#calculation
  display: grid
  grid-template-columns: auto auto auto
  grid-template-rows: auto auto auto
  grid-template-areas: "numerator equals score" "divider equals score" "denominator equals score"
  gap: .5rem

  @media screen and (max-width: 550px)
    font-size: .75rem

#numerator
  grid-area: numerator
  display: flex
  flex-direction: row
  justify-content: center
  gap: .25rem

#divider
  grid-area: divider
  width: 100%
  border-top: 1px solid $secondary-text-color

#denominator
  grid-area: denominator
  display: flex
  flex-direction: row
  justify-content: center
  gap: .25rem

#equals
  grid-area: equals
  align-self: center

#score
  grid-area: score
  align-self: center

.toolTip
  position: relative
  display: inline-block
  border-bottom: 1px dashed white

  .toolTipText
    width: 200px
    background-color: black
    color: #fff
    text-align: center
    padding: 5px 0
    border-radius: 6px

    position: absolute
    left: 50%
    bottom: 0
    transform: translateX(-50%) translateY(100%)
    z-index: 1
    opacity: 0
    transition: opacity .25s

    @media screen and (max-width: 550px)
      font-size: .75rem

  &:hover
    .toolTipText
      opacity: 1

#finalTip
  font-size: .9rem
  text-align: center
  opacity: .6
</style>
