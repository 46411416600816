<template>
  <div class="calculator">
    <transition name="fade">
      <AddScorePopup
        @dismissPopup="dismissAddScorePopup()"
        v-if="addScorePopupVisible"
      />
    </transition>
    <transition name="fade">
      <ExplainScorePopup
        @dismissPopup="dismissExplainScorePopup()"
        v-if="explainScorePopupVisible"
      />
    </transition>
    <ScoreHeader />
    <i id="infoButton" @click="showExplainScorePopup()" class="fas fa-info"></i>
    <ScoreList msg="Welcome to Your Vue.js App" />
    <AddButton id="userAddButton" @click="showAddScorePopup()" />
  </div>
</template>

<script>
// @ is an alias to /src
import AddScorePopup from "@/components/AddScorePopup.vue";
import ScoreHeader from "@/components/ScoreHeader.vue";
import ExplainScorePopup from "@/components/ExplainScorePopup.vue";
import ScoreList from "@/components/ScoreList.vue";
import AddButton from "@/components/AddButton.vue";

export default {
  name: "Home",
  components: {
    AddScorePopup,
    ScoreHeader,
    ExplainScorePopup,
    ScoreList,
    AddButton,
  },
  data() {
    return {
      addScorePopupVisible: false,
      explainScorePopupVisible: false,
    };
  },
  methods: {
    showAddScorePopup() {
      this.addScorePopupVisible = true;
    },
    dismissAddScorePopup() {
      this.addScorePopupVisible = false;
    },
    showExplainScorePopup() {
      this.explainScorePopupVisible = true;
    },
    dismissExplainScorePopup() {
      this.explainScorePopupVisible = false;
    },
  },
};
</script>

<style lang="sass" scoped>
.calculator
  padding: 1rem
  display: flex
  flex-direction: column
  // border: 3px solid $tertiary-background
  gap: 2rem

#infoButton
  position: fixed
  top: 1rem
  right: 1rem

  cursor: pointer
  width: 2.25rem
  height: 2.25rem
  outline: none
  border: none
  text-decoration: none
  clip-path: circle(50% at 50% 50%)
  background: $secondary-color
  color: $primary-text-color
  font-size: 1rem
  display: flex
  justify-content: center
  align-items: center

  transition: background .25s

  &:hover
    background: $primary-color
.fade-enter-active,
.fade-leave-active
  transition: opacity 0.1s ease-in-out, transform .1s ease-in-out

.fade-enter-from,
.fade-leave-to
  opacity: 0
  transform: scale(1.1)
</style>
