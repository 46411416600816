<template>
  <transition name="fade">
    <div
      v-if="shown"
      class="scoreEntryContainer"
      @click="remove()"
      :class="{
        aboveScore:
          Math.round($store.getters.currentScore) < score || score == 100,
        atScore: Math.round($store.getters.currentScore) == score,
        belowScore: Math.round($store.getters.currentScore) > score,
      }"
    >
      <div class="cover">
        <i class="fas fa-trash"></i>
      </div>
      <CalItem id="cal" :date="date" />
      <div class="score row">
        <i class="fas fa-tachometer-alt"></i>
        <span class="scoreSpan">{{ score }}</span>
      </div>
      <div class="distance row">
        <!-- <i class="fas fa-ruler"></i> -->
        <span>
          {{ distance }}
          <span class="unit">(mi)</span>
        </span>
      </div>
    </div>
  </transition>
</template>

<script>
import CalItem from "@/components/CalItem.vue";

export default {
  name: "ScoreEntry",
  props: ["id", "date", "score", "distance"],
  components: { CalItem },
  data() {
    return {
      shown: false,
      aboveScore: false,
      atScore: false,
      belowScore: false,
    };
  },
  methods: {
    remove() {
      setTimeout(() => {
        this.$store.commit("removeScore", this.id);
      }, 300);
      this.shown = false;
    },
  },
  mounted() {
    this.shown = true;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
.scoreEntryContainer
  position: relative
  display: grid
  width: 180px
  grid-template-columns: auto 1fr
  grid-template-rows: auto auto
  grid-template-areas: "cal score" "cal distance"
  gap: .5rem

  padding: 1.5rem
  box-shadow: $neu-extrude
  cursor: pointer
  border-radius: .5rem

.cover
  z-index: 5
  display: flex
  justify-content: center
  align-items: center
  position: absolute
  left: 0
  top: 0
  width: 100%
  height: 100%
  opacity: 0
  background: transparentize($tertiary-accent-color, .9)
  backdrop-filter: blur(1px)
  transition: opacity .25s

  &:hover
    opacity: 1

  i
    color: transparentize($tertiary-accent-color, .4)
    font-size: 2.5rem

#cal
  grid-area: cal

.row
  display: flex
  flex-direction: row
  align-items: center
  justify-content: flex-start
  position: relative

  span
    font-size: 1.25rem

  i
    transform: scale(1.5)
    opacity: .25

.score
  display: flex
  flex-direction: row
  justify-content: center
  gap: .5rem
  padding: 0 1rem
  border-radius: 10rem
  background: $secondary-background
  grid-area: score
  span
    font-size: 1.75rem
    color: $primary-text-color

.distance
  grid-area: distance
  display: flex
  flex-direction: row
  justify-content: center
  gap: .5rem
  span
    color: $secondary-text-color
    opacity: .9
    .unit
      font-size: .75rem
  i
    opacity: .0625
    margin-right: -1rem

.aboveScore
  border: 1px solid transparentize($primary-accent-color, .8) !important

.atScore
  border: 1px solid transparentize($secondary-accent-color, .8)

.belowScore
  border: 1px solid transparentize($tertiary-accent-color, .8)

.fade-enter-active,
.fade-leave-active
  transition: opacity .3s ease-in-out, transform .3s ease-in-out, border .75s ease-in-out

.fade-enter-from,
.fade-leave-to
  opacity: 0
  transform: scale(.9) translateY(50%)
  border: 1px solid transparent
</style>
